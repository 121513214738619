import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { playAudio } from 'utils';
import { fetchPickingBySKU } from 'services/smart';
import { message } from 'components/common';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartScanner,
  SmartBackButton,
  SmartSpin,
} from '../../../SmartComponent';
import { useTranslation } from 'react-i18next';
import { pickingItemBySKU2 } from 'services/smart';
import {Modal, Typography} from 'antd'


type ScanSKUProps = {
  onSubmit: (product: SmartPickSKU) => void;
};
export default function ScanSKU(props: ScanSKUProps): JSX.Element {
  const { onSubmit } = props;
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);
  const history = useHistory();

    const handleQuickPick = useCallback(
      async (product) => {
        setFetching(true);
          try {
            const params = [{
              PickListNum: product.pickListNum,
              LocationCode: 'General',
              SKU: product.sku || product.upc,
              PickedQty: product.pickQty,
              LotNumber: '',
              UOM: product.uom || 'EA',
              UomRate: product.uomRate || 1,
            }]
            const res = await pickingItemBySKU2(params);
            setFetching(false);
            if (res.isSuccess) {
              Modal.info({
                title: <Typography.Text type="success" strong>{t("smart.pick.quickPickMessage",{value: product.sku})}</Typography.Text>,
                content: <Typography.Text>{t("smart.pick.quickPickContent")}</Typography.Text>,
                onOk: () => {
                }
              })
            }
          } catch (error) {
            message.error('Quick Pick Error')
            setFetching(false);
          }
      },
      [])
  /**
   * handle sku key down event
   */
  const handleScannerChange = useCallback(
    async (nextValue: string) => {
      if (nextValue.length > 0) {
        setFetching(true);
        try {
          const res = await fetchPickingBySKU(nextValue);
          setFetching(false);
          if (res.isSuccess) {
            if (res.data.pickQty <= 0) {
              playAudio('error');
              message.warning(`'${nextValue}' had done!`);
            } else {
              if(res.data.quickPick === 1){
                handleQuickPick(res.data)
              } else {
                playAudio('success');
                onSubmit(res.data);
              }
            }
          } else {
            playAudio('error');
            message.warning(`'${nextValue}' not found!`);
          }
        } catch (error) {
          message.error('Quick Pick Error')
          setFetching(false);
        }
      }
    },
    [onSubmit, handleQuickPick]
  );
  return (
    <>
      <SmartSpin loading={fetching} />
      <SmartSpace>
        <SmartFormLabel
          style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
        >
          {t("smart.pick.scanToStart")}
        </SmartFormLabel>
        <SmartScanner
          style={{ marginTop: 10 }}
          onChangeValue={handleScannerChange}
        />
      </SmartSpace>
      <SmartBackButton
        onClick={() => {
          history.goBack();
        }}
      />
    </>
  );
}

import React from 'react';

function PrepackSVG({ style = {}, height = 16, width = 16, viewBox = '0 0 24 24', className = '' }) {
  return (
    <svg
      className={className}
      height={height}
      viewBox={viewBox}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M10.697 10.5156L18.4526 6.51757L10.697 2.51953L2.94141 6.51757M10.697 10.5156L2.94141 6.51757M10.697 10.5156V20.5107L2.94141 16.5127V6.51757M22.0537 10.7361H17.7942V16.2227M17.7942 16.2227V21.6808H22.0537M17.7942 16.2227H22.0537M17.7942 16.2227H14.4082M14.9075 16.132C14.9075 15.7193 14.5729 15.3848 14.1603 15.3848C13.7476 15.3848 13.4131 15.7193 13.4131 16.132C13.4131 16.5446 13.7476 16.8792 14.1603 16.8792C14.5729 16.8792 14.9075 16.5446 14.9075 16.132ZM23.1125 10.6714C23.1125 10.2587 22.778 9.92419 22.3654 9.92419C21.9527 9.92419 21.6182 10.2587 21.6182 10.6714C21.6182 11.084 21.9527 11.4186 22.3654 11.4186C22.778 11.4186 23.1125 11.084 23.1125 10.6714ZM23.1125 16.132C23.1125 15.7193 22.778 15.3848 22.3654 15.3848C21.9527 15.3848 21.6182 15.7193 21.6182 16.132C21.6182 16.5446 21.9527 16.8792 22.3654 16.8792C22.778 16.8792 23.1125 16.5446 23.1125 16.132ZM23.1125 21.6808C23.1125 21.2681 22.778 20.9336 22.3654 20.9336C21.9527 20.9336 21.6182 21.2681 21.6182 21.6808C21.6182 22.0934 21.9527 22.428 22.3654 22.428C22.778 22.428 23.1125 22.0934 23.1125 21.6808Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
}

export default PrepackSVG;

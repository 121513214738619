import React, { useCallback, useState } from 'react';
import { playAudio } from 'utils';
import { message } from 'components/common';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartScanner,
  SmartRow,
  SmartText,
  SmartBackButton,
  SmartSpin,
} from '../../../SmartComponent';
import { useTranslation } from 'react-i18next';
import SmartTable from './SmartTable';
import { pickingByOrder2 } from 'services/smart';
import {Modal, Typography} from 'antd'

type ScanSKUProps = {
  order: SmartPickOrder;
  uomEnable: boolean;
  onBack: () => void;
  onSubmit: (product: SmartPickOrderItem) => void;
  onRefresh:() => void
};
export default function ScanSKU(props: ScanSKUProps): JSX.Element {
  const { onSubmit, order, onBack, uomEnable, onRefresh } = props;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const isInAllCode = (productAllCode:string[], uom: string, enable:boolean, nValue: string) => {
    const temp = enable ? productAllCode.map(i=> `${i}_${uom}`) : [...productAllCode]
    return temp.indexOf(nValue) > -1
  }

    const handleSubmit = useCallback(
      async (product) => {
        setLoading(true);
        try {
          const params = [{
            FulfillmentOrderNum: order.fulfillmentOrderNum,
            LocationCode: 'General',
            FulfillmentOrderLineNum: product.fulfillmentOrderLineNum,
            Title: product.title,
            SkuOrUpc: product.sku || product.upc,
            PickQty: product.pickQty,
            PickedQty: product.pickQty,
            FulfillmentProductNum: product.fulfillmentProductNum,
            LotNumber: '',
            UOM: product.uom,
            UomRate: product.uomRate,
          }]
          const res = await pickingByOrder2(params);
          if (res.isSuccess) {
            setLoading(false);
              const { items } = order;
              const tempItems = items.filter((i:any)=> i.fulfillmentOrderLineNum !== product.fulfillmentOrderLineNum)
              let orderFinished = true;
              tempItems.forEach((item) => {
                if (item.pickQty > item.pickedQty) {
                  orderFinished = false;
                }
              });
              if(orderFinished){
                Modal.info({
                  title: <Typography.Text type="success" strong>{t("smart.pick.quickPickMessage",{value: product.sku})}</Typography.Text>,
                  content: <Typography.Text>{t("smart.pick.quickPickAllFinished")}</Typography.Text>,
                  onOk: () => {
                    onBack()
                  }
                })
              } else {
                Modal.info({
                  title: <Typography.Text type="success" strong>{t("smart.pick.quickPickMessage",{value: product.sku})}</Typography.Text>,
                  content: <Typography.Text>{t("smart.pick.quickPickContent")}</Typography.Text>,
                  onOk: () => {
                  }
                })
              }
              onRefresh()
              // setFinish(orderFinished ? 'allFinished' : 'finished');
          }
        } catch (error) {
          message.error('Quick Pick Error')
          setLoading(false);
   
        }
      },
      [order, onBack, onRefresh]
    );
  
  /**
   * handle scanner change event
   */
  const handleScannerChange = useCallback(
    async (nextValue: string) => {
      if (nextValue.length > 0) {
        //Check whether the sku is in the order range
        const { items } = order;
        const orderSKU = items.filter(
          (item) => `${item.sku}${uomEnable ? '_' : ''}${uomEnable? item.uom : ''}` === nextValue || 
          `${item.upc}${uomEnable ? '_' : ''}${uomEnable? item.uom : ''}` === nextValue ||
          isInAllCode(item.productAllCode||[], item.uom,uomEnable, nextValue)
        );
        // Cannot find sku in this order
        if (orderSKU.length < 1) {
          playAudio('error');
          message.warning(`'${nextValue}' invalid!`);
          return;
        }
        if (orderSKU.length >= 1) {
          const skuArr = orderSKU.filter((i)=> i.pickQty > i.pickedQty )
          if(skuArr.length > 0) {
            if(skuArr[0].quickPick){
              handleSubmit(skuArr[0])
            }else {
              playAudio('success');
              onSubmit(skuArr[0]);
            }
          } else {
            playAudio('error');
              message.warning(`'${nextValue}' had done!`);
          }
          // for (const sku of orderSKU) {
          //   if (sku.pickQty <= sku.pickedQty) {
          //     playAudio('error');
          //     message.warning(`'${nextValue}' had done!`);
          //     return;
          //   } else {
          //     playAudio('success');
          //     onSubmit(sku);
          //   }
          // }
        }
      }
    },
    [onSubmit, order, uomEnable, handleSubmit]
  );
  return (
    <>
      <SmartSpin loading={loading} />
      <SmartSpace>
        <SmartRow>
          <SmartFormLabel>
            {t("common.order")}
          </SmartFormLabel>
          <SmartText>{order.fulfillmentOrderNum}</SmartText>
        </SmartRow>
        <SmartFormLabel
          style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
        >
          {t("smart.pick.scanSkuUpcContinue")}
        </SmartFormLabel>
        <SmartScanner
          style={{ marginTop: 10 }}
          onChangeValue={handleScannerChange}
        />
      </SmartSpace>
      <SmartTable showLot={false} showUom={uomEnable} dataSource={order.items} />
      <SmartBackButton type="warning" onClick={onBack} />
    </>
  );
}

import React from 'react';

function POSVG({ style = {}, height = 16, width = 16, viewBox = '0 0 24 24', className = '' }) {
  return (
    <svg
      className={className}
      height={height}
      viewBox={viewBox}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.5502 6.7141L9.79464 2.71606L2.03906 6.7141M17.5502 6.7141L9.79464 10.7121M17.5502 6.7141V8.50928M9.79464 10.7121L2.03906 6.7141M9.79464 10.7121V20.7072M2.03906 6.7141V16.7092L9.79464 20.7072M9.79464 20.7072L11.5325 19.8588M13.1447 17.1209H23.1139M18.1973 22.2745V11.9345" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}

export default POSVG;

import React, { useState, useEffect } from 'react';
import { Form, Row, Spin, Modal, Button, Space, Radio } from 'antd';
import { message, FormLabel } from 'components/common';
import {
  LoadingOutlined,
  SaveOutlined,
  CloseOutlined,
} from '@ant-design/icons';
// import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { eidtQuickPickById } from 'services/product';

interface AddProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  product: ProductRow;
}

// eslint-disable-next-line
export default (props: AddProps) => {
  const { visible, onHide, onRefresh, product } = props;
  //   const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    const { quickPick } = await form.validateFields();
    try {
      setSubmitLoading(true);
      const res = await eidtQuickPickById(product.productId, quickPick);

      setSubmitLoading(false);
      if (res) {
        message.success({
          content: 'Saved successfully',
        });
        onRefresh();
        onHide();
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  };
  /* eslint-disable */
  useEffect(() => {}, []);
  /* eslint-enable */
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };
  return (
    <Modal
      title="Quick Pick Option"
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="save_button"
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
            >
              <SaveOutlined />
              {submitLoading ? 'Saving' : 'Save'}
            </Button>
            <Button
              id="cancel_button"
              onClick={handleCancel}
              icon={<CloseOutlined />}
            >
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={submitLoading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form}>
          <Form.Item label={<FormLabel>SKU</FormLabel>}>
            {product.sku}
          </Form.Item>
          <Form.Item
            name="quickPick"
            initialValue={product.quickPick}
            label={<FormLabel>Enable Quick Pick on this item</FormLabel>}
          >
            <Radio.Group>
              <Radio value={1}>Enable</Radio>
              <Radio value={0}>Disable</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

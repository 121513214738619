export const siteCfg = {
  'fulfillmentweb.z4.web.core.chinacloudapi.cn': {
    name: 'DigitBridge(dev)',
    logo: '/imgs/LOGO-185X185.png',
    logo185: '/imgs/LOGO-185X185.png',
  },
  'localhost:3000': {
    name: 'DigitBridge(L)',
    logo: '/imgs/LOGO-185X185.png',
    logo185: '/imgs/LOGO-185X185.png',
  },
  'wmsappdev.digitbridge.com': {
    name: 'DigitBridge(dev)',
    logo: '/imgs/LOGO-185X185.png',
    logo185: '/imgs/LOGO-185X185.png',
  },
  'wmsapppre.digitbridge.com': {
    name: 'DigitBridge(pre)',
    logo: '/imgs/LOGO-185X185.png',
    logo185: '/imgs/LOGO-185X185.png',
  },
  'wmsapp.digitbridge.com': {
    name: 'DigitBridge',
    logo: '/imgs/LOGO-185X185.png',
    logo185: '/imgs/LOGO-185X185.png',
  },
  'wmsapp02.digitbridge.com': {
    name: 'DigitBridge(02)',
    logo: '/imgs/LOGO-185X185.png',
    logo185: '/imgs/LOGO-185X185.png',
  },
  'dbgfuljfjpmanswdev.z19.web.core.windows.net': {
    name: 'JFJP(dev)',
    logo: '/imgs/JFJP-logo.png',
    logo185: '/imgs/JFJP-logo.png',
  },
  'dbgfuljfjpmansw.z19.web.core.windows.net': {
    name: 'JFJP',
    logo: '/imgs/JFJP-logo.png',
    logo185: '/imgs/JFJP-logo.png',
  },
};

import React, { SVGProps } from 'react';

const DS_MARKETPLACE_BG: React.FC<SVGProps<SVGAElement>> = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.966797" width="40" height="40" rx="10" fill="#094074"></rect>
      <path fillRule="evenodd" clipRule="evenodd" d="M26.8047 17.1025C26.9475 16.9916 27.1719 16.9907 27.3159 17.1007C27.641 17.3488 27.9097 17.5055 28.1702 17.6009C28.4252 17.6942 28.6911 17.7357 29.0228 17.7304C29.1822 17.7278 29.3374 17.7051 29.4877 17.6635C29.6013 17.6321 29.7265 17.6471 29.8241 17.704C29.9216 17.7609 29.9794 17.8526 29.9794 17.9502L29.9497 25.9175C29.9497 26.0848 29.7832 26.2203 29.5778 26.2203H12.296C12.0906 26.2203 11.924 26.0848 11.924 25.9175L11.9538 17.9903C11.9538 17.9024 12.0007 17.8188 12.0823 17.7613C12.164 17.7037 12.2723 17.6779 12.3792 17.6906C12.4319 17.6968 12.4855 17.7011 12.5398 17.7034C13.2602 17.7332 13.952 17.5497 14.5876 17.0946C14.7306 16.9922 14.9457 16.9943 15.0856 17.0996C15.6953 17.5584 16.5485 17.6474 16.888 17.6474C17.0119 17.6474 17.341 17.6298 17.7082 17.5489C18.0793 17.4673 18.4475 17.3302 18.6958 17.1148C18.7649 17.0548 18.8611 17.0203 18.9622 17.0192C19.0633 17.0182 19.1605 17.0507 19.2316 17.1092C19.7579 17.543 20.2957 17.7155 20.9877 17.7304C21.6349 17.7444 22.147 17.5336 22.7392 17.0975C22.8133 17.0429 22.9112 17.0149 23.0109 17.0197C23.1106 17.0246 23.2037 17.0619 23.2693 17.1232C23.6907 17.5175 24.448 17.7303 25.1018 17.7303C25.4868 17.7303 25.7797 17.6706 26.0353 17.5691C26.296 17.4656 26.5354 17.3119 26.8047 17.1025ZM15.3637 20.9283H18.2779V26.2203H15.3637V20.9283ZM26.7236 20.9283H21.1169V24.3148H26.7236V20.9283Z" fill="white"></path>
      <path d="M13.0722 11.3329C13.1204 11.2202 13.2519 11.1445 13.3996 11.1445H28.795C28.9496 11.1445 29.0856 11.2273 29.1281 11.3475L30.6874 15.114C30.7092 15.1755 30.7042 15.2412 30.6733 15.3001C30.224 16.1563 29.4616 16.6897 28.5871 16.7036C27.8823 16.7148 27.2263 16.3868 26.7261 15.7921C26.2296 16.4692 25.5267 16.8265 24.7654 16.8014C24.0477 16.7778 23.3947 16.4163 22.9358 15.8263C22.4836 16.4337 21.8426 16.7648 21.1169 16.7493C20.3867 16.7337 19.6915 16.3664 19.1518 15.7551C18.655 16.3924 17.9794 16.7243 17.2405 16.6967C16.5217 16.6699 15.8582 16.3046 15.3637 15.7219C14.7511 16.3837 13.9744 16.7097 13.2103 16.6783C12.3019 16.6409 11.5493 16.1034 11.2464 15.2697C11.2262 15.2139 11.2279 15.1544 11.2514 15.0995L13.0722 11.3329Z" fill="white"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.2673 27.4238C11.2673 27.2027 11.4896 27.0235 11.7638 27.0235H30.1704C30.4445 27.0235 30.6668 27.2027 30.6668 27.4238V28.4558C30.6668 28.6769 30.4445 28.8561 30.1704 28.8561H11.7638C11.4896 28.8561 11.2673 28.6769 11.2673 28.4558V27.4238Z" fill="white"></path>
    </svg>
  );
};

export default DS_MARKETPLACE_BG;

import React from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import {
  Button,
  Col,
  Image,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
  Dropdown,
  Menu,
  Input,
} from 'antd';
import { CaretDownOutlined, CloudDownloadOutlined, DiffOutlined, DownOutlined, RightOutlined, SettingOutlined } from '@ant-design/icons';
import { setShipmentItem } from 'actions/salesAction';
import fallbackImage from 'assets/images/logo192.png';
import {
  ButtonIcon,
  CellNumberInput,
  Loading,
  ScreenMask,
  message,
} from 'components/common';
import { InfoButton, Label } from 'components/common/styles';
import ExportLabelPDF from 'components/Sales/ShipmentList/LablePdf';
import {
  LOADING_ICON_SIZE1,
  SHIPMENT_CANCELLED_STATE,
  SHIPMENT_ITEM_NOT_VERIFY_STATE,
  SHIPMENT_ITEM_VERIFY_STATE,
  SHIPMENT_SHIPPING_STATE, /* shipped status */
  SHIPMENT_NOT_SHIPPING_STATE,
} from 'constants/config';
import { RootState } from 'reducers';
import { fetchShipmentItemsById } from 'services/sales';
import { exportShipmentPackingSlip, moveLinesToShipment, newShipment, holdShipment, unholdShipment } from 'services/shipment';
import OptionDialog from './OptionDialog';
import { ShipmentListItem } from './ManageShipment';
import { ShipmentItem } from '..';
import {
  postExportShipmentLabelPdf,
} from 'services/sales';
import { convertTimeByUtc, nowTime } from 'utils';
import ShipmentDetail from '../../ShipmentList/ShipmentDetailDialog'
import BundleModal from '../../../Catalog/CatalogView/BundleModal';
import CancelShipmentDialog from 'components/Sales/ShipmentList/CancelShipmentDialog';
import { userProfiles } from 'utils/auth';
import {
  shipmentStatusKeyValue as statusKeyValue,
} from 'constants/enums'

type Props = {
  focusShipment: Function;
  getShipmentInList: Function;
  loadShipment: Function;
  refreshShipment: Function;
  removeShipment: Function;
  shipment: ShipmentListItem;
  shipmentList: ShipmentListItem[];
  style?: StringKAnyVPair;
  warehouseNum: number;
  showDetailButton?: boolean;
};

type ShipmentLine = ShipmentItem & {
  selectedQty: number;
};

const ShipmentWrapper = (props: Props) => {
  const dispatch: Dispatch<any> = useDispatch();
  const { useState } = React;
  const {
    focusShipment,
    getShipmentInList,
    loadShipment,
    refreshShipment,
    removeShipment: removeShipmentInList,
    showDetailButton,
  } = props;
  const [currentFocused, setCurrentFocused] = useState(false);
  const [currentShipment, setCurrentShipment] = useState({} as ShipmentListItem);
  const [editItemCount, setEditItemCount] = useState(props.shipment.editItemCount);
  const [inited, setInited] = useState(false);
  //const [itemLoaded, setItemLoaded] = useState(false);
  const [lockScreen, setLockScreen] = useState(false);
  const [optionDialogVisible, setOptionDialogVisible] = useState(false);
  const [shipment, setShipment] = useState(props.shipment);
  const [shipmentItemList, setShipmentItemList] = useState<ShipmentLine[]>([]);
  const [shipmentListLength, setShipmentListLength] = useState(props.shipmentList.length);
  const [shipmentRemoved, setShipmentRemoved] = useState(false);
  const [targetShipmentList, setTargetShipmentList] = useState<ShipmentListItem[]>([]);
  const [targetShipmentNum, setTargetShipmentNum] = useState<number>();
  const reason = React.useRef<string>('');
  const wrapperRef = React.useRef<any>(null);
  const shipmentItem: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.shipmentItem,
    shallowEqual
  );
  const [cancelDialogVisible, setCancelDialogVisible] = useState<boolean>(false);
  const [exportLablePdfVisible, setExportLablePdfVisible] = useState(false);
  const [shipmentDetailVisible, setShipmentDetailVisible] = useState(false);
  const [bundleModalVisible, setBundleModalVisible] = useState(false);
  const [current, setCurrent] = useState<any>();
  const expandAll = React.useMemo(() => {
    const { shipmentList } = props;

    return shipmentList.every(e => e.isExpanded);
  }, [props]);

  const enableUOM = userProfiles.getProfileSettingValue('EnableUOM') === '1';
  const enableLOT = userProfiles.getProfileSettingValue('EnableLOT') === '1';

  const columns = [
    {
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      title: '',
      visible: true,
      render(url: string) {
        return (
          <Row align="middle" justify="center">
            <Image
              fallback={fallbackImage}
              width={44}
              height={44}
              preview={false}
              src={url || 'error'}
            />
          </Row>
        );
      },
    },
    {
      dataIndex: 'sku',
      key: 'title',
      title: '',
      visible: true,
      render(sku: string, data: StringKAnyVPair) {
        return (
          <Row align="middle" justify="start">
            <Col>
              <Row>
                <Label className="label-bold">{data.title}</Label>
              </Row>
              <Row>
                <Label>
                  <span className="label-bold">SKU: </span>
                  <span className="label-grey">{data.sku}</span>
                </Label>
              </Row>
              <Row>
                <Label>
                  <span className="label-bold">UPC: </span>
                  <span className="label-grey">{data.upc}</span>
                </Label>
              </Row>
              {enableLOT && (<>
                {/*<Row>
                  <Label>
                    <span className="label-bold">LOT: </span>
                    <span className="label-grey">{data.lotNumber}</span>
                  </Label>
                </Row>*/}
              </>)}
              {enableUOM && (
                <Row>
                  <Label>
                    <span className="label-bold">UOM: </span>
                    <span className="label-grey">{data.uom}</span>
                  </Label>
                </Row>
              )}
              {data.bundleType === 1 && <Row>
                <Label>
                  <span className="label-grey">Component of <Typography.Link onClick={() => {
                    setCurrent({
                      productId: data.bundleProductId,
                      sku: data.bundleSKU,
                      upc: data.bundleUPC,
                      productTitle: data.bundleTitle,
                    })
                    setBundleModalVisible(true);
                  }}>{data.bundleSKU}</Typography.Link><span>{`(${data.bundleQty}x${data.orderQty / data.bundleQty})`}</span></span>
                </Label>
              </Row>}
            </Col>
          </Row>
        );
      },
    },
    {
      dataIndex: 'itemTitle',
      key: 'skuTitle',
      title: 'Product Name',
      // width: '30%',
      visible: true,
    },
    {
      align: 'center' as 'center',
      dataIndex: 'shippingQty',
      key: 'shippingQty',
      title: 'Remaining Qty',
      visible: true,
      render(_: any, data: StringKAnyVPair) {
        return {
          children: getRemainingItemValue(data),
          props: {
            style: { fontSize: 22 },
          },
        };
      },
    },
    {
      align: 'center' as 'center',
      key: 'selected',
      title: 'Selected',
      visible: true,
      render(_: any, data: StringKAnyVPair) {
        return {
          children: <CellNumberInput
            align="center"
            disabled={data.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE}
            height={50}
            key={data.centralOrderLineNum}
            max={data.shippingQty}
            min={0}
            paddingleft={4}
            radius={0}
            size="large"
            style={{ width: '100%' }}
            //value={getSelectedItemValue(data)}
            value={data.selectedQty}
            onChange={(val) => handleSelectedQuantityChange(val as number, data)}
          //onFocus={(evt) => handleFocusScanNumberInput(evt, data)}
          />,
          props: {
            style: { padding: 0 },
          },
        };
      },
    },
    {
      align: 'center' as 'center',
      dataIndex: 'uom',
      //key: 'itemUom',
      title: 'UOM',
      // width: '30%',
      visible: enableUOM,
    },
    {
      align: 'center' as 'center',
      dataIndex: 'uomRate',
      key: 'itemUomRate',
      title: 'Rate',
      // width: '30%',
      visible: enableUOM,
    },
    {
      align: 'center' as 'center',
      dataIndex: 'uomBaseQty',
      key: 'itemUomBaseQty',
      title: 'Base Qty',
      // width: '30%',
      visible: enableUOM,
    },
  ];

  const deductShipmentItems = (shipmentNum: number) => {
    const items = shipmentItemList.filter(e => e.shippingQty > e.selectedQty)
      .map(e => ({
        ...e,
        shippingQty: e.shippingQty - e.selectedQty,
        selectedQty: 0,
      }));

    //return setShipmentItemsInDict(shipmentNum, dup);
    setShipmentItemList([...items]);

    return items;
  };

  const getItemRowClassName = (data: ShipmentLine) => {
    let ret = '';

    if (data.shippingQty > 0 && data.shippingQty === data.selectedQty) {
      ret = 'shipment-item-all-selected';
    }

    return ret;
  };

  const getOrderShipmentIcon = (shipment: ShipmentListItem) => {
    let ret = 'bi-printer';

    if (shipment.shippingStatus === SHIPMENT_SHIPPING_STATE) {
      ret = 'bi-truck';
    } else if (shipment.shippingStatus === SHIPMENT_CANCELLED_STATE) {
      ret = 'bi-folder-x';
    } else {
      if (shipment.verifyStatus === SHIPMENT_ITEM_NOT_VERIFY_STATE) {
        ret = 'bi-upc-scan';
      }
    }

    return ret;
  };

  const getOrderShipmentStatusLabel = (shipment: ShipmentListItem) => {
    let ret = 'Shipped';

    if (isShipmentEditable(shipment)) {
      ret = shipment.shippingStatus === SHIPMENT_CANCELLED_STATE ? 'Cancelled'
        : statusKeyValue[shipment.shippingStatus];
    }

    if (shipment.shippingStatus === 32) {
      ret = 'Abandoned';
    }

    return ret;
  };

  const getRemainingItemValue = (item: StringKAnyVPair) => {
    return item.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE ? item.shippingQty : item.shippingQty - item.selectedQty;
  };

  const getShipmentItems = async (shipmentNum: number) => {
    let ret: ShipmentItem[] = [];

    try {
      const list = await fetchShipmentItemsById(shipmentNum, true);

      ret = list.map((e) => ({
        ...e,
        scanError: false,
        scanMessage: '',
        selectedQty: 0,
        verifyStatus: shipment.shippingStatus === SHIPMENT_CANCELLED_STATE ? SHIPMENT_ITEM_NOT_VERIFY_STATE : e.verifyStatus,
      }));
    } catch (e) {
      message.error(`Fetch shipment items error: ${e}`);
    }

    return ret as ShipmentLine[];
  };

  const getShipmentTitleTooltip = (shipment: ShipmentListItem) => {
    const {
      shippingCarrier,
      shippingService,
      trackingNumber,
      actualShipDate,
    } = shipment;
    const tips = [
      shippingCarrier ? `Carrier: ${shippingCarrier}` : null,
      shippingService ? `Service: ${shippingService}` : null,
      trackingNumber ? `Tracking Number: ${trackingNumber}` : null,
      actualShipDate ? `Ship Date: ${convertTimeByUtc(shipment.actualShipDate)}` : null,
    ];

    return tips.filter(e => e).join(', ');
  };

  // const getShipmentTitleWidth = (shipment: ShipmentListItem) => {
  //   if (shipment.shippingStatus === SHIPMENT_SHIPPING_STATE) {
  //     console.log('im here');
  //     return 720;
  //   }
  //   console.log('im there');
  //   return 590;
  // };

  const getWrapperClassName = (shipment: ShipmentListItem) => {
    let cls = ['shipment-wrapper'];

    if (shipment.isFocused) cls.push('focus');

    return cls.join(' ');
  };

  const handleUnholdShipment = async () => {
    setLockScreen(true);
    try {
      const res = await unholdShipment(
        shipment.shipmentNum,
        reason.current,
      );
      reason.current = '';
      if (res.isSuccess) {
        message.success({ content: 'Unhold successfully' });
        shipment.shippingStatus = SHIPMENT_NOT_SHIPPING_STATE;
        refreshShipment(shipment);
      }
    } catch (error) {
      reason.current = '';
    } finally {
      setLockScreen(false);
    }
  };

  const handleHoldShipment = async () => {
    setLockScreen(true);
    try {
      const res = await holdShipment(
        shipment.shipmentNum,
        reason.current,
      );
      reason.current = '';
      if (res.isSuccess) {
        message.success({ content: 'Hold successfully' });
        shipment.shippingStatus = 8;
        refreshShipment(shipment);
      }
    } catch (error) {
      reason.current = '';
    } finally {
      setLockScreen(false);
    }
  };

  const handleCreateShipment = async () => {
    const data = shipmentItemList.filter(e => e.selectedQty)
      .map(e => ({
        Quantity: e.selectedQty,
        ShipmentLineNum: e.shipmentLineNum,
      }));

    if (data.length === 0) {
      message.error('Please select items you want to move to the new shipment');
      return;
    }

    setLockScreen(true);

    try {
      const newShipmentNum = await newShipment(shipment.shipmentNum, data);

      setLockScreen(false);

      if (newShipmentNum > 0) {
        deductShipmentItems(shipment.shipmentNum);

        const [addedShipment] = await loadShipment(newShipmentNum);

        if (addedShipment) {
          //console.log('popup property editor', addedShipment);
          message.info('Created new shipment successfully');
          openOptionDialog(addedShipment);
        }
      } else {
        message.error('Failed to create new shipment');
      }
    } catch (e) {
      setLockScreen(false);
      message.error(`Created shipment error: ${e}`);
    }
  };

  const handleMoveShipment = () => {
    if (typeof targetShipmentNum !== 'number') {
      message.error('Please select a shipment');
      return;
    }

    const data = shipmentItemList.filter(e => e.selectedQty)
      .map(e => ({
        Quantity: e.selectedQty,
        ShipmentLineNum: e.shipmentLineNum,
      }));

    if (data.length === 0) {
      message.error('Please select items you want to move to shipment');
      return;
    }

    moveShipment(shipment.shipmentNum, targetShipmentNum, data);
  };

  const handleOpenOptionDialog = (evt: any) => {
    openOptionDialog(shipment);
    evt.stopPropagation();
  };

  /**
   * Handler for changing the selected item to be splitted.
   */
  const handleSelectedQuantityChange = (
    value: number,
    data: StringKAnyVPair
  ) => {
    const qty = value >= 0 && value <= data.shippingQty ? value : 0;

    data.selectedQty = qty;
    setShipmentItemList([...shipmentItemList]);
  };

  const isCancelNotAvaliable = (shipment: ShipmentListItem) => {
    return shipment.shippingStatus === SHIPMENT_SHIPPING_STATE ||
      shipment.shippingStatus === SHIPMENT_CANCELLED_STATE || shipment.shippingStatus === 32;
  };

  const isShipmentEditable = (shipment: ShipmentListItem) => {
    return shipment.shippingStatus !== SHIPMENT_SHIPPING_STATE && shipment.shippingStatus !== 32;
  };

  const isShipmentVerified = (shipment: ShipmentListItem) => {
    return shipment.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE && shipment.shippingStatus !== SHIPMENT_CANCELLED_STATE && shipment.shippingStatus !== 32;
  };

  const isSelectedQtyCreatable = (shipment: ShipmentListItem) => {
    const selNum = shipmentItemList.reduce((a1, a2) => a1 + a2.selectedQty, 0);
    const total = shipmentItemList.reduce((a1, a2) => a1 + a2.shippingQty, 0);

    return selNum > 0 && total > selNum;
  };

  const isSelectedQtyOperatable = (shipment: ShipmentListItem) => {
    const selNum = shipmentItemList.reduce((a1, a2) => a1 + a2.selectedQty, 0);

    return selNum > 0;
  };

  // eslint-disable-next-line
  const loadShipmentItems = async (shipmentNum: number, sh = shipment) => {
    /*if (!wrapperRef?.current) {
      return [];
    }*/
    setLockScreen(true);

    const items = await getShipmentItems(shipmentNum);

    sh.isItemLoaded = true;
    setLockScreen(false);
    //setShipment({...shipment});
    setShipment({ ...sh });
    setShipmentItemList(items);
    //refreshShipment(shipment);
    refreshShipment(sh);

    return items;
  };

  const moveShipment = async (
    fromShipmentNum: number,
    toShipmentNum: number,
    data: StringKAnyVPair[],
  ) => {
    setLockScreen(true);

    try {
      const res = await moveLinesToShipment(fromShipmentNum, toShipmentNum, data);

      setLockScreen(false);

      const remaining = deductShipmentItems(fromShipmentNum);
      const targetShipment = getShipmentInList(res);
      let needRemove = false;

      if (remaining.length >= 0) {
        const total = remaining.reduce((a1, a2) => a1 + a2.shippingQty, 0);

        if (total === 0) {
          needRemove = true;
        }
      }

      if (targetShipment) {
        const { editItemCount } = targetShipment;

        targetShipment.editItemCount = editItemCount + 1;

        //let list = refreshShipment(targetShipment);
        let list = props.shipmentList;

        if (needRemove) {
          list = removeShipmentInList(shipment, list);
          focusShipment(targetShipment, list);
          setShipmentRemoved(true);
          message.info('Removed empty shipment successfully');
        }

        refreshShipment(targetShipment, list);
        setTargetShipmentNum(undefined);
        message.info(`Moved item(s) to '${targetShipment.shipmentTitle}' successfully`);
      } else {
        message.error('Move item(s) failed');
      }
    } catch (e) {
      setLockScreen(false);
      message.error(`Move items to another shipment error: ${e}`);
    }
  };

  const openOptionDialog = (targetShipment?: ShipmentListItem) => {
    setCurrentShipment(targetShipment || shipment);
    setOptionDialogVisible(true);
  };

  const onSelectMoveTargetShipment = (val: number) => {
    const value = typeof val === 'number' ? val : undefined;
    //console.log('value', value, typeof val);
    setTargetShipmentNum(value);
  };

  // eslint-disable-next-line
  const resetTargetShipmentList = () => {
    const { shipmentList } = props;
    const list = shipmentList.filter(e => e.shipmentNum !== shipment.shipmentNum)
      .filter(e => e.shippingStatus !== SHIPMENT_SHIPPING_STATE && e.verifyStatus !== SHIPMENT_ITEM_VERIFY_STATE);

    setTargetShipmentList([...list]);
  };

  const toggleShipment = async (shipment: ShipmentListItem) => {
    const { isExpanded, isFocused, isItemLoaded, shipmentNum } = shipment;

    //if (itemLoaded) {
    if (isItemLoaded) {
      if (!isFocused && isExpanded) {
        focusShipment(shipment);
        return;
      }
    } else {
      await loadShipmentItems(shipmentNum);
      //setItemLoaded(true);
    }

    shipment.isExpanded = !isExpanded;

    const list = refreshShipment(shipment);

    setShipment(shipment);
    focusShipment(shipment, list);
  };

  const toolbarJustifyProps = (shipment: ShipmentListItem) => {
    return shipment.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE && shipment.shippingStatus !== SHIPMENT_CANCELLED_STATE ?
      'end' : 'space-between';
  };

  const tryFocusShipment = (shipment: ShipmentListItem) => {
    if (!shipment.isFocused) {
      focusShipment(shipment);
    }
  };

  React.useEffect(() => {
    if (!inited) {
      resetTargetShipmentList();
      setInited(true);

      if (props.shipment.isExpanded && !props.shipment.isItemLoaded) {
        const sh = { ...props.shipment, isFocused: true };
        const list = props.refreshShipment(sh);

        loadShipmentItems(sh.shipmentNum, sh);
        setShipment(sh);
        props.focusShipment(sh, list);
      }
    }

    if (shipment.height !== props.shipment.height ||
      shipment.length !== props.shipment.length ||
      shipment.weight !== props.shipment.weight ||
      shipment.width !== props.shipment.width ||
      shipment.isExpanded !== props.shipment.isExpanded ||
      shipment.isFocused !== props.shipment.isFocused ||
      shipment.shippingServiceCode !== props.shipment.shippingServiceCode ||
      shipment.shipPackageNum !== props.shipment.shipPackageNum ||
      shipment.signatureRequired !== props.shipment.signatureRequired
    ) {
      setShipment(props.shipment);
    }

    if (shipmentItem && shipmentItem.shipmentNum === shipment.shipmentNum) {
      //console.log('-->', shipmentItem);
      if (Array.isArray(shipmentItem.items)) {
        const sh = {
          ...shipment,
          isItemLoaded: true,
        };

        setShipmentItemList(shipmentItem.items);
        setShipment(sh);
        props.focusShipment(sh);
      }

      dispatch(setShipmentItem({}));
    }

    if (!shipmentRemoved && editItemCount !== props.shipment.editItemCount) {
      console.log('edit count change');
      if (shipment.isItemLoaded || expandAll) {
        loadShipmentItems(props.shipment.shipmentNum);
        //console.log('ecc->', props.shipment.shipmentNum);
      }

      setEditItemCount(props.shipment.editItemCount);
    }

    if (!shipmentRemoved && shipmentListLength !== props.shipmentList.length) {
      //console.log(props.shipmentList.length, '<-');
      resetTargetShipmentList();
      setShipmentListLength(props.shipmentList.length);
    }

    if (!shipmentRemoved && currentFocused !== shipment.isFocused) {
      setCurrentFocused(shipment.isFocused);

      if (shipment.isFocused) {
        wrapperRef?.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [
    currentFocused,
    dispatch,
    editItemCount,
    expandAll,
    inited,
    //itemLoaded,
    loadShipmentItems,
    resetTargetShipmentList,
    props,
    shipment,
    shipmentItem,
    shipmentListLength,
    shipmentRemoved,
  ]);

  const handleExportShippingLabel = async (data: any) => {
    if (data.shippingStatus === 1) {
      try {
        const params = {
          isBatch: false,
          ShipmentNum: data.shipmentNum,
          ShippingAccountNum: data.shippingAccountNum,
          ShipFrom: data.warehouseNum,
          //ShipFrom: warehouseId,
          //ShipFrom: 10032,
          PrinterAccountNum: 0,
          PrinterNum: 0,
          PrinterName: "0",
          Carrier: data.shippingCarrier,
          CarrierNum: data.shipCarrierNum,
          Confirmation: data.signatureRequired,
          Service: data.shippingService,
          PackageName: data.packageName,
          PackageType: data.packageCode,
          ShipPackageNum: data.packageNum,
          ShipServiceNum: data.shipServiceNum,
          Height: data.height,
          Length: data.length,
          Weight: data.weight,
          Width: data.width,
          MasterAccountNum: data.masterAccountNum,
          ProfileNum: data.profileNum,
          InsuredValue: data.insuredValue,
          BillToType: data.billToType,
          BillThirdPartyAccount: data.billThirdPartyAccount,
          BillThirdPartyCountry: data.billThirdPartyCountry,
          BillThirdPartyPostalCode: data.billThrdPartyPostalcode,
          CN22: data.cN22
        }
        const res = await postExportShipmentLabelPdf(params);
        // const content = res;
        // const blob = new Blob([content], { type: 'application/pdf' });
        // const fileName = `Shipment#${data.shipmentNum}.pdf`;
        const fileType = res.headers[`content-type`]
        const content = res.data;
        const blob = new Blob([content], { type: fileType });
        const fileName = fileType === 'application/pdf' ? `Shipment-${data.shipmentNum}.pdf` : `Shipment-${data.shipmentNum}.png`;

        if ('download' in document.createElement('a')) {
          const elink = document.createElement('a');
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        }
      } catch (error) {
        console.log('error = ', error)
      }
    } else {
      message.error({ content: `shipment status(${statusKeyValue[data.shippingStatus]}) cann't be export.` })
    }
  }

  const handleExportPackingSlip = async (slip: StringKAnyVPair) => {
    // console.log('slip', slip);
    setLockScreen(true);

    try {
      const res = await exportShipmentPackingSlip(
        slip.fulfillmentOrderNum,
        slip.shipmentNum,
        nowTime()
      );
      setLockScreen(false);
      const pdfData = new Blob([res], { type: 'application/pdf' });
      const fileURL = window.URL.createObjectURL(pdfData);

      window.open(fileURL, 'preview.pdf');
    } catch (e) {
      setLockScreen(false);
      console.log(`export packing slip error ${e}`);
    }
  };

  return (
    <>
      <div
        className={getWrapperClassName(shipment)}
        ref={wrapperRef}
        style={props.style || {}}
      >
        <Row
          align="middle"
          justify="space-between"
          onClick={() => toggleShipment(shipment)}
        >
          <Col className="header-col-1">
            <Space className="wrapper-space">
              <span className="shipment-expand-icon-box">
                {shipment.isExpanded ? <DownOutlined /> : <RightOutlined />}
              </span>
              <span className="shipment-title">
                <i className={getOrderShipmentIcon(shipment)} />
                &nbsp;
                {shipment.shipmentTitle}
              </span>
              <Tag color="#2db7f5">{getOrderShipmentStatusLabel(shipment)}</Tag>
              <Typography.Text
                ellipsis={{ tooltip: getShipmentTitleTooltip(shipment) }}
              // style={{ width: getShipmentTitleWidth(shipment) }}
              >
                <Tag color="gold">{shipment.shipmentId}</Tag>
                {shipment.shippingCarrier && (
                  <Tag color="cyan">Carrier: {shipment.shippingCarrier}</Tag>
                )}
                {shipment.shippingService && (
                  <Tag color="geekblue">
                    Service: {shipment.shippingService}
                  </Tag>
                )}
                {shipment.trackingNumber && (
                  <Tag color="volcano">
                    Tracking Number: {shipment.trackingNumber}
                  </Tag>
                )}
                {shipment.actualShipDate && (
                  <Tag color="purple">
                    Ship Date: {convertTimeByUtc(shipment.actualShipDate)}
                  </Tag>
                )}
              </Typography.Text>
            </Space>
          </Col>
          <Col>
            <Space>
              {showDetailButton && (
                <Button
                  size="small"
                  onClick={(e) => {
                    setShipmentDetailVisible(true);
                    e.stopPropagation();
                  }}
                >
                  Detail
                </Button>
              )}
              {shipment.shippingStatus !== 32 && <span className="shipment-option-icon-box">
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item style={{ paddingLeft: 0 }}>
                        <Button
                          type="text"
                          style={{ width: '100%', textAlign: 'left' }}
                          icon={<DiffOutlined />}
                          onClick={() => {
                            if (shipment.shippingStatus === 1) {
                              handleExportShippingLabel(shipment);
                            } else {
                              setExportLablePdfVisible(true);
                            }
                          }}
                        >
                          Shipping Label
                        </Button>
                      </Menu.Item>
                      <Menu.Item style={{ paddingLeft: 0 }}>
                        <Button
                          type="text"
                          style={{ width: '100%', textAlign: 'left' }}
                          icon={<DiffOutlined />}
                          onClick={() => handleExportPackingSlip(shipment)}
                        >
                          Packing Slip
                        </Button>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <Button size="small" onClick={(e) => e.stopPropagation()}>
                    <CloudDownloadOutlined />
                    Export PDF
                    <CaretDownOutlined />
                  </Button>
                </Dropdown>
              </span>}
              {shipment.shippingStatus !== 1 && shipment.shippingStatus !== 32 && !isCancelNotAvaliable(shipment) && (
                <span className="shipment-option-icon-box">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item style={{ paddingLeft: 0 }}>
                          <Button
                            type="text"
                            className="hover-danger-button-2"
                            style={{ width: '100%', textAlign: 'left' }}
                            onClick={() => {
                              Modal.confirm({
                                title: <span>
                                  <div>Do you want to {shipment.shippingStatus === 8 ? 'unhold' : 'hold'} this shipment?</div>
                                  <div>Channel Order ID: {shipment.channelOrderId}</div>
                                  <div>Shipment ID: {shipment.shipmentNum}</div>
                                  <br />
                                  <div>{shipment.shippingStatus === 8 ? 'Unhold Reason' : 'Hold Reason'}: </div>
                                </span>,
                                okText: 'Yes',
                                cancelText: 'No',
                                okType: 'default',
                                okButtonProps: { className: "hover-danger-button" },
                                autoFocusButton: 'cancel',
                                content: (
                                  <div style={{ paddingRight: 38 }}>
                                    <Input.TextArea
                                      rows={2}
                                      style={{ marginTop: -2 }}
                                      placeholder={
                                        shipment.shippingStatus === 8
                                          ? 'Please input the unhold reason'
                                          : 'Please input the hold reason'
                                      }
                                      onChange={(event) =>
                                        (reason.current = event.target.value)
                                      }
                                    />
                                  </div>
                                ),
                                onCancel() {
                                  reason.current = '';
                                },
                                onOk() {
                                  if (shipment.shippingStatus === 8) {
                                    handleUnholdShipment();
                                  } else {
                                    handleHoldShipment();
                                  }
                                },
                              });
                            }}
                          >
                            {shipment.shippingStatus === 8
                              ? 'Unhold Shipment'
                              : 'Hold Shipment'}
                          </Button>
                        </Menu.Item>
                        {!isCancelNotAvaliable(shipment) && shipment.shippingStatus !== 64 &&(
                          <Menu.Item style={{ paddingLeft: 0 }}>
                            <Button
                              type="text"
                              className="hover-danger-button-2"
                              style={{ width: '100%', textAlign: 'left' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setCancelDialogVisible(true);
                              }}
                            >
                              Cancel Shipment
                            </Button>
                          </Menu.Item>
                        )}
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <Button size="small" onClick={(e) => e.stopPropagation()}>
                      {shipment.shippingStatus === 8 ? 'Unhold' : 'Hold'} / Cancel
                      <CaretDownOutlined />
                    </Button>
                  </Dropdown>
                </span>
              )}
              {/* {!isCancelNotAvaliable(shipment) && (
                <span
                  className="shipment-option-icon-box"
                  onClick={() => setCancelDialogVisible(true)}
                >
                  <Button size="small" className="hover-danger-button">
                    Cancel Shipment
                  </Button>
                </span>
              )} */}
              {isShipmentEditable(shipment) && shipment.shippingStatus !== 64 && (
                <span
                  className="shipment-option-icon-box"
                  onClick={handleOpenOptionDialog}
                >
                  <Button size="small">
                    <SettingOutlined />
                    Services
                  </Button>
                </span>
              )}
            </Space>
          </Col>
        </Row>
        {shipment.isExpanded && isShipmentEditable(shipment) && (
          <Row
            align="middle"
            className="shipment-toolbar"
            justify={toolbarJustifyProps(shipment)}
            onClick={() => tryFocusShipment(shipment)}
          >
            <Space>
              {!isShipmentVerified(shipment) && (
                <>
                  <Button
                    type="primary"
                    disabled={!isSelectedQtyCreatable(shipment)}
                    onClick={handleCreateShipment}
                  >
                    <ButtonIcon className="bi bi-plus-lg" />
                    New Shipment
                  </Button>
                  <InfoButton
                    disabled={
                      !isSelectedQtyOperatable(shipment) ||
                      typeof targetShipmentNum !== 'number'
                    }
                    onClick={handleMoveShipment}
                  >
                    Move To
                  </InfoButton>
                  <Select
                    allowClear
                    className="move-shipment-selector"
                    disabled={
                      !isSelectedQtyOperatable(shipment) &&
                      targetShipmentList.length > 0
                    }
                    onChange={onSelectMoveTargetShipment}
                    placeholder="Select Target Shipment"
                    value={targetShipmentNum}
                  >
                    {targetShipmentList.map((e) => (
                      <Select.Option key={e.shipmentNum} value={e.shipmentNum}>
                        {e.shipmentTitle}
                      </Select.Option>
                    ))}
                  </Select>
                </>
              )}
            </Space>
          </Row>
        )}
        {shipment.isExpanded && (
          <div onClick={() => tryFocusShipment(shipment)}>
            <Table
              columns={columns.filter(col=> col.visible)}
              dataSource={shipmentItemList}
              rowClassName={getItemRowClassName}
              rowKey={(row) => row.shipmentLineNum}
              pagination={false}
            />
          </div>
        )}
      </div>
      {optionDialogVisible && (
        <OptionDialog
          onClose={() => setOptionDialogVisible(false)}
          refreshShipment={refreshShipment}
          shipment={currentShipment}
          visible={optionDialogVisible}
          warehouseNum={props.warehouseNum}
        />
      )}
      {lockScreen && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
      {
        cancelDialogVisible && (
          <CancelShipmentDialog
            shipmentInfo={shipment}
            visible={cancelDialogVisible}
            onClose={() => setCancelDialogVisible(false)}
            refreshShipment={() => {
              shipment.shippingStatus = SHIPMENT_CANCELLED_STATE;
              refreshShipment(shipment);
            }}
          />
        )
      }
      {exportLablePdfVisible && (
        <ExportLabelPDF
          visible
          onClose={() => {
            setExportLablePdfVisible(false);
          }}
          onRefresh={() => { }}
          shipment={shipment}
          markVerified={true}
        />
      )}
      {shipmentDetailVisible && (
        <ShipmentDetail
          onClose={() => setShipmentDetailVisible(false)}
          shipmentInfo={shipment}
          visible
          isHistory={false}
        />
      )}
      {
        bundleModalVisible && current && (
          <BundleModal
            visible
            onHide={() => setBundleModalVisible(false)}
            product={current}
          />
        )
      }
    </>
  );
};

export default ShipmentWrapper;

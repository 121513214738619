import React from 'react';
import {
  Alert,
  Button,
  Image,
  Input,
  Modal,
  Row,
  Space,
  notification,
} from 'antd';

import fallbackImage from 'assets/images/logo192.png';
import { SHIPMENT_ITEM_VERIFY_STATE, DEFAULT_US_DATE_FORMAT } from 'constants/config';
import DataGrid from 'components/common/DataGrid0';
import { CellNumberInput } from 'components/common/styles/CustomPropsComponents';
import { playBell } from 'utils';
import {
  ColumnDataType,
} from 'components/common';
import { LotSelectorDialogBody } from './styles';

type Props = {
  lines: StringKAnyVPair[];
  lots: StringKAnyVPair[];
  onVerify: Function;
  onClose: Function;
  visible: boolean;
};

const LotSelector = (props: Props) => {
  const { useState } = React;
  const [inited, setInited] = useState(false);
  const [lineIdx, setLineIdx] = useState<number>(-1);
  const [lineLots, setLineLots] = useState<StringKAnyVPair[]>([]);
  const [rawLines, setRawLines] = useState<StringKAnyVPair[]>([]);
  const [searchCode, setSearchCode] = useState('');
  //const [selLots, setSelLots] = useState<StringKAnyVPair[]>([]);
  const [prdQty, setPrdQty] = useState(0);
  //let searchCode = '';
  let searchCodeTimer: any = 0;

  const columns = [
    {
      cellProps: { className: 'lot-selector-item-cell' },
      header: 'Lot #',
      name: 'lotNumber',
      sortable: false,
      textAlign: 'center',
    },
    {
      cellProps: { className: 'lot-selector-item-cell' },
      header: 'First in Date',
      name: 'firstInDate',
      sortable: false,
      textAlign: 'center',
      dataType: ColumnDataType.DATE,
      format: DEFAULT_US_DATE_FORMAT,
    },
    {
      cellProps: { className: 'lot-selector-item-cell' },
      header: 'Expiration Date',
      name: 'expirationDate',
      sortable: false,
      textAlign: 'center',
      dataType: ColumnDataType.DATE,
      format: DEFAULT_US_DATE_FORMAT,
    },
    {
      cellProps: { className: 'lot-selector-item-cell' },
      header: 'Location Code',
      name: 'locationCode',
      sortable: false,
      textAlign: 'center',
    },
    {
      cellProps: { className: 'lot-selector-item-cell' },
      header: 'UOM',
      name: 'uom',
      sortable: false,
      textAlign: 'center',
      maxWidth: 120,
    },
    {
      cellProps: { className: 'lot-selector-item-cell' },
      header: 'Available Qty',
      name: 'lotQty',
      sortable: false,
      textAlign: 'center',
      maxWidth: 140,
    },
    {
      cellProps: { className: 'lot-selector-item-cell' },
      header: 'Verify Qty',
      headerAlign: 'center',
      name: 'selQty',
      sortable: false,
      maxWidth: 140,
      render(row: any) {
        const { data } = row;

        return (
          <CellNumberInput
            align="center"
            height={60}
            //id={`scan_${data.shipmentLineNum}`}
            //key={data.shipmentLineNum}
            min={0}
            paddingleft={4}
            radius={0}
            size="large"
            style={{ width: 'calc(100% - 12px)' }}
            value={data.selQty}
            onChange={n => onPickLotItem(data, n)}
            //onFocus={(evt) => handleFocusScanNumberInput(evt, data)}
          />
        );
      },
    },
  ];


  // const backupLines = (lines: StringKAnyVPair[]) => {
  //   const lineCopy = JSON.parse(JSON.stringify(lines));

  //   lineCopy.forEach((line: StringKAnyVPair) => {
  //     if (Array.isArray(line.inventory)) {
  //       line.inventory.forEach(lot => {
  //         checkLineLot(lot);
  //       });
  //     }
  //   });

  //   //console.log('lineC', lineCopy);
  //   return lineCopy;
  // };

  const currentLineImage = () => {
    return props.lines[lineIdx] ? (props.lines[lineIdx].thumbnail || 'error') : 'error';
  };

  const currentLineSKU = () => {
    return props.lines[lineIdx] ? props.lines[lineIdx].sku : '';
  };

  const currentLineUPC = () => {
    return props.lines[lineIdx] ? props.lines[lineIdx].upc : '';
  };

  const dialogFooter = () => {
    return (<>
      <Row
        justify="space-between"
      >
        <Space>
          {isPreviousButtonEnable() && (
            <Button
              type="primary"
              size="large"
              onClick={() => switchLine(-1)}
            >
              Previous
            </Button>
          )}
        </Space>
        <Space>
          <Button onClick={onCancel} size="large">
            Cancel
          </Button>
          {isNextButtonEnable() && (
            <Button
              type="primary"
              size="large"
              onClick={() => switchLine(1)}
            >
              Next
            </Button>
          )}
          {isLastButtonEnable() && (
            <Button
              disabled={!isSavable()}
              onClick={verifyLots}
              size="large"
              type="primary"
            >
              Ok
            </Button>
          )}
        </Space>
      </Row>
    </>);
  };

  const dialogTitle = () => {
    const line = props.lines[lineIdx];
    let lt = '';

    if (line) {
      const identifier = line.sku || line.upc;

      if (identifier) lt = `for ${identifier}`;
    }

    return `Select LOT ${lt}`;
  };

  const dialogWidth = () => {
    const max = window.innerWidth <= 1280 ? 1280 : 1440;
    const calc = window.innerWidth * 0.95;

    return calc > max ? max : calc;
  };

  const isLastButtonEnable = () => {
    let ret = false;

    if (props.lines.length > 0) {
      ret = lineIdx + 1 === props.lines.length;
    }

    return ret;
  }

  const isNextButtonEnable = () => {
    let ret = false;

    if (props.lines.length > 1) {
      ret = lineIdx < props.lines.length - 1;
    }

    return ret;
  };

  const isPreviousButtonEnable = () => {
    let ret = false;

    if (props.lines.length > 1) {
      ret = lineIdx > 0;
    }

    return ret;
  };

  const isSavable = () => {
    const pn = pickedItemNumber();

    return lineIdx > -1 && lineIdx < props.lines.length && pn > 0 && pn <= prdQty;
  };

  const onCancel = () => {
    const lineDict: StringKAnyVPair = {};

    rawLines.forEach(line => lineDict[line.shipmentLineNum] = line);
    props.lines.forEach(line => {
      const lc = lineDict[line.shipmentLineNum];

      if (lc) {
        for (let k in lc) {
          if (k in line) {
            line[k] = lc[k];
          }
        }
      }
    });

    props.onClose();
  };

  const onPickLotItem = (lot: StringKAnyVPair, n: any) => {
    const inc = n - lot.selQty;
    //console.log('pick', lot, n);
    if (pickedItemNumber() + inc <= prdQty) {
      pickItemInLot(lot, n);
      setLineLots([...lineLots]);
    } else {
      playBell('error');
      notification.error({
        message: 'Can not select any more',
        duration: 10,
      });
    }
  };

  const onSearchKeyUp = (evt: any) => {
    const val = evt.target.value;

    if (searchCodeTimer) {
      clearTimeout(searchCodeTimer);
      searchCodeTimer = 0;
    }

    if (evt.keyCode !== 13) {
      searchCodeTimer = setTimeout(() => {
        searchLotCode(val.trim());
      }, 1000);
    }
  };

  const pickedItemNumber = (idx = lineIdx) => {
    return selectedLineLots(idx).reduce((a, b) => a + (typeof b.selQty === 'number' ? b.selQty : 0), 0);
  };

  const searchLotCode = (sCode: string) => {
    const code = sCode.trim();

    if (code === searchCode) return;

    setSearchCode(code);
  };

  const selectedLineLots = (idx = lineIdx) => {
    const line = props.lines[idx];
    const lots: StringKAnyVPair[] = (line && 'inventory' in line) ? line.inventory : [];

    return lots.filter(l => line && line.uom === l.uom)
      .filter(l => l.lotNumber.toLowerCase().indexOf(searchCode.toLowerCase()) > -1);
  };

  // eslint-disable-next-line
  const setNextLineLots = (step = 1) => {
    const lines = props.lines;
    //const lots = props.lots;
    const idx = lineIdx + step;
    const rLots: StringKAnyVPair[] = [];
    let lineQty = 0;

    for (let i = idx; i < lines.length; i++) {
      const line = lines[i];

      //console.log('line -->', line);
      /*for (let j = 0; j < lots.length; j++) {
        const lot = lots[j];

        if (line.fulfillmentProductNum === lot.fulfillmentProductNum && line.uom === lot.uom) {
          rLots.push(lot);
        }
      }*/

      if (Array.isArray(line.inventory)) {
        //line.inventory.forEach(lot => rLots.push({...lot, lotQty: lot.quantity, selQty: 0}));
        if (props.lines[idx].autoFillType !== 0) {
          let temp  = 0;

          line.inventory.forEach(lot => {
            if (line.shippingQty > temp) {
              if (line.shippingQty - temp >= lot.quantity) {
                if (!('lotQty' in lot)) lot.lotQty = 0;
                if (!('selQty' in lot)) lot.selQty = lot.quantity;
                temp = temp + lot.quantity;
              } else {
                if (!('lotQty' in lot) || typeof lot.lotQty === 'number') lot.lotQty = lot.quantity - (line.shippingQty - temp);
                if (!('selQty' in lot) || !lot.selQty) lot.selQty = line.shippingQty - temp;
                temp = line.shippingQty;
              }
            } else {
              checkLineLot(lot);
            }
            rLots.push(lot);
          });
        } else {
          line.inventory.forEach(lot => {
            checkLineLot(lot);
            rLots.push(lot);
          });
        }
      }

      lineQty = line.shippingQty;
      break;
    }
    setLineIdx(idx);
    setLineLots(rLots);
    setPrdQty(lineQty);
    setRawLines(props.lines)
  };

  const switchLine = (step = 1) => {
    /*const i = lineIdx + step;

    if (i >= 0 && i < props.lines.length) {
      setLineIdx(i);
    }*/
    verifyLine();
    setNextLineLots(step);
  };

  const verifyLine = (idx = lineIdx) => {
    const line = props.lines[idx];

    if (line) {
      line.verifyQty = pickedItemNumber(idx);

      if (line.verifyQty === line.shippingQty) {
        line.verifyStatus = SHIPMENT_ITEM_VERIFY_STATE;
      }
    }
  };

  const verifyLots = () => {
    props.lines.forEach((line, i) => verifyLine(i));
    //verifyLine();
    props.onVerify();
    //onCancel();
    props.onClose();
  };

  React.useEffect(() => {
    if (!inited) {
      setNextLineLots()
      setInited(true);
    }
  }, [inited, props, setNextLineLots]);

  return (<>
    <Modal
      centered
      destroyOnClose
      footer={dialogFooter()}
      maskClosable={false}
      onCancel={onCancel}
      title={dialogTitle()}
      visible={props.visible}
      width={dialogWidth()}
    >
      <LotSelectorDialogBody>
        <Row justify="space-between">
          <Row align="middle">
            <Space>
              <Image
                fallback={fallbackImage}
                width={76}
                height={76}
                preview={false}
                src={currentLineImage()}
              />
              <div>
                <span className="title">SKU</span>
                <span>{currentLineSKU()}</span>
                {currentLineUPC() && (<>
                  <br />
                  <span className="title">UPC</span>
                  <span>{currentLineUPC()}</span>
                </>)}
              </div>
            </Space>
          </Row>
          <Row
            className="item-total-counter"
            align="middle"
            justify="center"
          >
            {pickedItemNumber()}/{prdQty}
          </Row>
        </Row>
        <div
          style={{padding: '12px 0', width: 300}}
        >
          <Input.Search
            allowClear
            onKeyUp={onSearchKeyUp}
            onSearch={searchLotCode}
            placeholder="Input LOT code here"
            size="large"
          />
        </div>
        <div style={{height: 300}}>
          <DataGrid
            //key={verifyRowHeight}
            columns={columns}
            dataSource={selectedLineLots()}
            emptyText={<Alert className="no-lot-warning-text" message="No LOTs available" type="warning" />}
            //renderRow={renderLineRow}
            //renderRowDetails={renderLineLot}
            //rowExpandColumn={{ visible: false }}
            //rowExpandHeight={lineRowDetailHeight}
            rowHeight={60}
            rowKey="lotNumber"
            //rowStyle={shipmentTableRowStyle}
            pagination={false}
            showHoverRows={false}
            style={{ height: '100%' }}
          />
          {/*selectedLots()*/}
        </div>
      </LotSelectorDialogBody>
    </Modal>
  </>);
};

export const checkLineLot = (lot: StringKAnyVPair) => {
  if (!('lotQty' in lot)) lot.lotQty = lot.quantity;
  if (!('selQty' in lot)) lot.selQty = 0;
};

export const getLineLots = (line: StringKAnyVPair) => {
  return Array.isArray(line.inventory) ? line.inventory.filter((l: StringKAnyVPair) => line.uom === l.uom) : [];
};

export const pickItemInLot = (lot: StringKAnyVPair, n: number) => {
  lot.lotQty = lot.quantity - n;
  lot.selQty = n;
};

export default LotSelector;

/**
 * prepack service requests are here.
 */
import { digitBridgeApi } from './http';

export const fetchLOTSettings = async (): Promise<any> => await digitBridgeApi.get(`/api/profile/lot/rule`);

type PostLOTSettingParams = {
  LOTSettingNum: number;
  Unique: number;
  Generation: number;
  Rule: number;
  Prefix: string;
  PrefixSeparator: string;
  StartNumber: number;
  DateFormat: string;
  AppendTime: number;
  TimeFormat: string;
  Suffix: string;
  SuffixSeparator: string;
  TotalLength: number;
  PaddingZero: number;
  MasterAccountNum: number;
  ProfileNum: number;
  CreateBy: string;
  CreateDate: string;
  UpdateBy: string;
  UpdateDate: string;
}
export const postLOTSettings = async (params: PostLOTSettingParams): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgeApi.post(`/api/profile/lot/rule`, json);
};

// product part

export const fetchProductLOTSettings = async (fulfillmentProductNum: number): Promise<any> => await digitBridgeApi.get(`/api/products/${fulfillmentProductNum}/lotRule`);

type updateProductLOTSettingsParams = {
  ProfileLotNumberRuleNum: number,
  Unique: number,
  Generation: number,
  Rule: number,
  Prefix: string,
  PrefixSeparator: string,
  StartNumber: number,
  DateFormat: string,
  AppendTime: number,
  TimeFormat: string,
  Suffix: string,
  SuffixSeparator: string,
  TotalLength: number,
  PaddingZero: number,
  MasterAccountNum: number,
  ProfileNum: number,
  CreateBy: string,
  CreateDate: string,
  UpdateBy: string,
  UpdateDate: string,
}

export const updateProductLOTSettings = async (fulfillmentProductNum: number, params: updateProductLOTSettingsParams): Promise<any> => await digitBridgeApi.post(`/api/products/${fulfillmentProductNum}/lotRule`, JSON.stringify(params));

export const fetchProductLOTRuleConfig = async (fulfillmentProductNum: number): Promise<any> => await digitBridgeApi.get(`/api/products/${fulfillmentProductNum}/lotRule/config`);

